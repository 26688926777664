<script setup lang="ts">
const loaded = ref(false);

onMounted(() => {
  loaded.value = true;
});
</script>
<template>
  <NuxtPage />
</template>
