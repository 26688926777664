<script setup lang="ts">
import Autoplay from "embla-carousel-autoplay";

const plugin = Autoplay({
  delay: 6000,
  stopOnMouseEnter: false,
  stopOnInteraction: false,
});
</script>

<template>
  <UtilsSection class="flex flex-col items-center relative overflow-hidden">
    <UtilsTitle
      class="text-center text-[#98ADC6] font-medium"
      data-aos="fade-up"
    >
      <template #default>
        {{ $t("testimonials.title1") }}
      </template>
      <template #append>
        <span class="font-neutron font-normal text-5xl italic text-[#EE3824]"
          >&nbsp;{{ $t("testimonials.title2") }}</span
        >
      </template>
    </UtilsTitle>
    <UtilsTitle as="h4" class="text-center" data-aos="fade-up">
      {{ $t("testimonials.subtitle") }}
    </UtilsTitle>
    <div class="w-full h-1 border-t-[2px] mt-5" data-aos="fade-up"></div>
    <template #append>
      <div
        class="font-neutron italic text-8xl font-extrabold text-white tracking-wider opacity-10 md:w-[600px] w-[100%] absolute md:left-1/2 top-5"
        style="
          text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        "
      >
        {{ $t("testimonials.title2") }}
      </div>
      <div class="relative w-full pb-10">
        <SvgoQuotation
          :font-controlled="false"
          :filled="true"
          class="w-[200px] absolute left-5 top-0"
        />
        <SvgoQuotation
          :font-controlled="false"
          :filled="true"
          class="w-[200px] absolute right-5 rotate-180 bottom-5"
        />
        <div class="pb-10 md:container md:max-w-screen-lg">
          <Carousel
          data-aos="fade-up"
            :opts="{
              loop: true,
              align: 'center',
            }"
            :plugins="[plugin]"
            v-slot="{ canScrollNext, canScrollPrev }"
            class="relative w-full"
          >
            <CarouselContent>
              <CarouselItem class="basis-[80%] md:basis-1/4">
                <UtilsTestimonialCard
                  :text="$t('testimonials.card1.text')"
                  name="Wanessa Souza"
                  user-image="/images/elements/user1.jpg"
                  maxheight
                />
              </CarouselItem>
              <CarouselItem class="basis-[80%] md:basis-1/4">
                <UtilsTestimonialCard
                  :text="$t('testimonials.card2.text')"
                  name="Odair Viana"
                  maxheight
                  user-image="/images/elements/user2.jpg"
                />
              </CarouselItem>
              <CarouselItem class="basis-[80%] md:basis-1/4">
                <UtilsTestimonialCard
                  :text="$t('testimonials.card3.text')"
                  name="Jonathan Silva"
                  maxheight
                  user-image="/images/elements/user3.jpg"
                />
              </CarouselItem>
              <CarouselItem class="basis-[80%] md:basis-1/4">
                <UtilsTestimonialCard
                  :text="$t('testimonials.card4.text')"
                  name="Maíra Araujo"
                  user-image="/images/elements/user4.jpg"
                  maxheight
                />
              </CarouselItem>
            </CarouselContent>
            <CarouselPrevious v-if="canScrollPrev" class="hidden md:flex" />
            <CarouselNext v-if="canScrollNext" class="hidden md:flex" />
          </Carousel>

          <div class="mt-10 flex items-center justify-center w-full" data-aos="fade-up">
            <UtilsButton as="a" href="#buy-now" scroll-to="buy-now">
              {{ $t("buy-now.button2") }}
            </UtilsButton>
          </div>
        </div>
      </div>
    </template>
  </UtilsSection>
</template>
