<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Vue3Lottie } from "vue3-lottie";

const { smaller } = useBreakpoints(breakpointsTailwind);

const isMobile = smaller("md");
</script>
<template>
  <UtilsSection
    class="flex flex-col relative bg-[url(/images/backgrounds/author-bg.jpg)] bg-cover overflow-hidden"
  >
    <UtilsTitle as="h2" data-aos="fade-up">
      {{ $t("author.title") }}
    </UtilsTitle>
    <div
      class="w-full h-1 border-t-[2px] border-gray-700/10 my-8"
      data-aos="fade-up"
    ></div>
    <div class="pb-[100vw] md:pb-0">
      <div class="md:w-1/2 w-full relative z-10">
        <div
          class="rounded-lg bg-gradient-to-r from-[#3A94FE]/60 to-[#27C9F4]/60"
          data-aos="fade-up"
        >
          <div
            class="border-4 border-transparent relative rounded-[9px] overflow-hidden"
          >
            <div class="bg-white/90 absolute inset-0"></div>
            <div
              class="md:px-10 px-5 py-8 rounded-3xl z-10 relative flex-col flex gap-3"
            >
              <p>
                <strong>Marcos Santi Scheffel</strong>
                {{ $t("author.description-p1") }}
              </p>
              <p>
                {{ $t("author.description-p2") }}
              </p>
              <p>
                {{ $t("author.description-p3") }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3 mt-4" data-aos="fade-up">
          <div class="bg-[#FF4D17] w-3 h-3 rounded-full mt-2"></div>
          <div class="font-neutron text-3xl text-primary-foreground italic">
            Marcos Scheffel
          </div>
        </div>
      </div>
      <div class="absolute md:left-[60%] -left-10 md:-translate-x-1/2 bottom-0">
        <ClientOnly>
          <Vue3Lottie
            animationLink="/lotties/author.json"
            height="auto"
            width="750px"
            class="hidden md:block"
            :speed="0.3"
          />
          <Vue3Lottie
            animationLink="/lotties/author.json"
            height="auto"
            width="100%"
            class="block md:hidden"
            :speed="0.3"
          />
        </ClientOnly>
      </div>
    </div>
  </UtilsSection>
</template>
