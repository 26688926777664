<script setup lang="ts">
import Separator from "../utils/Separator.vue";

const { locale } = useI18n();

const amazonLink = computed(() => {
  switch (locale.value) {
    case "it":
      return "https://www.amazon.it/dp/B0DLBQNYMN";
    case "en":
      return "https://www.amazon.com/dp/B0DKKVQX33";
    default:
      return "https://www.amazon.com.br/dp/B0DJFZ2NPJ";
  }
});

const price = computed(() => {
  switch (locale.value) {
    case "it":
      return {
        symbol: "€",
        amazon: {
          0: "25",
          1: "39",
        },
      };
    case "en":
      return {
        symbol: "$",
        amazon: {
          0: "22",
          1: "79",
        },
      };
    default:
      return {
        symbol: "R$",
        amazon: {
          0: "68",
          1: "10",
        },
      };
  }
});
</script>
<template>
  <UtilsSection container-class="flex flex-col items-center" id="buy-now">
    <UtilsTitle as="h2" class="text-center text-5xl md:text-5xl">
      {{ $t("buy-now.button2") }}
    </UtilsTitle>
    <div class="flex gap-5 w-full flex-col">
      <p data-aos="fade-up" class="text-center text-xl leading-none">
        {{ $t("buy.title") }}
      </p>
      <h3
        data-aos="fade-up"
        class="text-center text-3xl font-normal text-primary-foreground leading-none"
      >
        {{ $t("buy.subtitle") }}
      </h3>
      <div>
        <div class="grid md:grid-cols-3 grid-cols-1 gap-5 mt-10">
          <UtilsCard
            class="h-full"
            rounded="lg"
            data-aos="fade-up"
            containerClass="order-2 md:-order-1 bg-[linear-gradient(to_bottom_right,#189BE670_0%,#189BE620_50%,#189BE670_100%)]"
            wrapperClass="py-8 items-start"
          >
            <div
              class="flex flex-col items-center justify-between h-full text-center"
            >
              <div>
                <div class="relative inline-block h-9">
                  <SvgoCheck
                    :font-controlled="false"
                    :filled="true"
                    class="w-12 left-0 top-1/2 drop-shadow-lg z-10 absolute -translate-y-1/2"
                  />
                  <div
                    class="bg-[#D0F5FF] top-1/2 -translate-y-1/2 rounded-full w-10 h-10 absolute shadow-lg"
                  ></div>

                  <div
                    class="pl-14 md:text-4xl text-3xl text-[#27C9F4] font-neutron italic -mt-2"
                  >
                    {{ $t("buy.printed") }}
                  </div>
                </div>
                <div
                  class="h-[2px] mt-8 w-full bg-gradient-to-r from-transparent via-[#21b3ed] to-transparent"
                ></div>
                <p class="mt-4 text-xl font-medium text-center text-black">
                  {{ $t("buy.printed-cover") }}
                </p>
                <p class="text-[#27C9F4] text-3xl italic font-neutron">
                  429 {{ $t("buy.pages") }}
                </p>
                <div class="flex items-center justify-center mt-2">
                  <img src="/images/flags/BR.svg" class="w-10 border" />
                </div>
                <div class="mt-4 text-center">
                  <div class="font-semibold text-gray-400 line-through">
                    De R$ 133,48
                  </div>
                  <div class="text-3xl font-semibold">
                    {{ $t("buy.for-only") }}:
                  </div>
                  <div class="text-4xl text-primary-foreground font-semibold">
                    R$ <span class="text-5xl">98</span>,60
                  </div>
                </div>
              </div>
              <UtilsButton
                class="mt-8"
                as="a"
                href="https://aartedainfluencialivroimpresso.carrinho.app/one-checkout/ocmtb/21367485"
                >{{ $t("buy-now.button2") }}</UtilsButton
              >
            </div>
          </UtilsCard>
          <div class="relative h-full">
            <UtilsCard
              class="h-full"
              rounded="lg"
              data-aos="fade-up"
              containerClass="bg-[linear-gradient(to_bottom_right,#189BE670_0%,#189BE620_50%,#189BE670_100%)] order-1 md:order-none h-full"
              wrapperClass="py-8 items-start"
            >
              <div
                class="flex flex-col items-center justify-between h-full text-center"
              >
                <div>
                  <div class="relative inline-block h-9">
                    <SvgoCheck
                      :font-controlled="false"
                      :filled="true"
                      class="w-12 left-0 top-1/2 drop-shadow-lg z-10 absolute -translate-y-1/2"
                    />
                    <div
                      class="bg-[#D0F5FF] top-1/2 -translate-y-1/2 rounded-full w-10 h-10 absolute shadow-lg"
                    ></div>

                    <div
                      class="pl-14 md:text-4xl text-3xl text-[#27C9F4] font-neutron italic -mt-2"
                    >
                      E-book
                    </div>
                  </div>
                  <div
                    class="h-[2px] mt-8 w-full bg-gradient-to-r from-transparent via-[#21b3ed] to-transparent"
                  ></div>
                  <p class="mt-4 text-xl font-medium text-center text-black">
                    {{ $t("buy.ebook-format") }}
                  </p>
                  <p class="text-[#27C9F4] text-3xl italic font-neutron">
                    Kindle | Epub | PDF
                  </p>
                  <div class="flex items-center justify-center mt-2">
                    <img src="/images/flags/BR.svg" class="w-10 border" />
                  </div>
                  <div class="mt-2 text-center">
                    <div class="font-semibold text-gray-400 line-through">
                      De R$ 56,90
                    </div>
                    <div class="text-3xl font-semibold">
                      {{ $t("buy.for-only") }}:
                    </div>
                    <div class="text-4xl text-primary-foreground font-semibold">
                      R$ <span class="text-5xl">42</span>,67
                    </div>
                  </div>
                </div>
                <UtilsButton
                  class="mt-8"
                  as="a"
                  href="https://aartedainfluencia.carrinho.app/one-checkout/ocmtb/20016026"
                  >{{ $t("buy-now.button2") }}</UtilsButton
                >
              </div>
            </UtilsCard>
          </div>
          <UtilsCard
            rounded="lg"
            data-aos="fade-up"
            class="h-full"
            containerClass="bg-[linear-gradient(to_bottom_right,#189BE670_0%,#189BE620_50%,#189BE670_100%)]"
            wrapperClass="py-8 items-start "
          >
            <div
              class="flex flex-col items-center justify-between h-full text-center"
            >
              <div>
                <div class="relative inline-block">
                  <SvgoAmazon
                    :font-controlled="false"
                    class="h-9"
                    :filled="true"
                  />
                </div>
                <div
                  class="h-[2px] mt-8 w-full bg-gradient-to-r from-transparent via-[#21b3ed] to-transparent"
                ></div>
                <p class="mt-4 text-xl font-medium text-center text-black">
                  {{ $t("buy.ebook-amazon-title") }}
                </p>
                <p class="text-[#27C9F4] text-3xl italic font-neutron">
                  <span v-if="locale === 'it'"> Stampato </span>
                  <span v-else> Kindle | Epub | PDF</span>
                </p>
                <div class="flex items-center justify-center mt-2">
                  <img
                    :src="
                      locale === 'it'
                        ? '/images/flags/IT.svg'
                        : locale === 'en'
                        ? '/images/flags/US.svg'
                        : '/images/flags/BR.svg'
                    "
                    class="w-10 border"
                  />
                </div>
                <div class="mt-2 text-center">
                  <span class="text-3xl font-semibold"
                    >{{ $t("buy.for-only") }}:
                  </span>
                  <div class="text-4xl text-primary-foreground font-semibold">
                    {{ price.symbol }}
                    <span class="text-5xl"> {{ price.amazon[0] }} </span>,{{
                      price.amazon[1]
                    }}
                  </div>
                </div>
              </div>
              <UtilsButton class="mt-8" as="a" :href="amazonLink">{{
                $t("buy-now.button2")
              }}</UtilsButton>
            </div>
          </UtilsCard>
        </div>
      </div>
    </div>
  </UtilsSection>
</template>
