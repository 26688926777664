<script setup lang="ts">
import { Primitive } from "radix-vue";
import { cn } from "~/lib/utils";

const props = withDefaults(
  defineProps<{
    variant?: "default" | "red";
    bordered?: boolean;
    class?: string;
    as?: string;
    disabled?: boolean;
    scrollTo?: string;
  }>(),
  {
    as: "button",
    bordered: false,
    variant: "default",
  }
);

function scrollTo(selector: string) {
  const element = document.getElementById(selector);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}
</script>

<template>
  <Primitive
    :as="props.as"
    @click="(e: Event) => {
      if (props.scrollTo) {
        e.preventDefault();
        scrollTo(props.scrollTo);
      }

    }"
    :class="
      cn(
        props.variant === 'default' &&
          'text-white border-[rgba(39,201,244,0.4)] bg-gradient-to-tr from-[#27C9F4] via-[#189BE6] via-[30%] to-[#035DD3]',
        props.variant === 'red' &&
          'bg-gradient-to-br from-[#FF6B3D] via-[#EE3824] to-[#D7311E] border-[#EE3824]',
        'group flex items-center relative border text-white font-semibold text-lg  px-8 py-2 rounded-lg hover:scale-105 transition-all overflow-hidden justify-center',

        bordered &&
          'bg-none border-[#27C9F4] text-[#27C9F4] hover:text-[#71E1FF] hover:border-[#71E1FF]',
        disabled && 'opacity-50 cursor-not-allowed animate-none hover:scale-100 pointer-events-none',
        props.class
      )
    "
    :disabled="props.disabled"
  >
    <div
      class="bg-gradient-to-r from-transparent via-white/20 to-transparent w-28 h-[300%] absolute z-20 top-[-20%] rotate-45 left-[-20%] -translate-x-1/2 -translate-y-1/2 group-hover:left-[120%] group-hover:top-[120%] transition-all duration-700 fill-mode-forwards"
    ></div>
    <slot />
    <slot name="append" />
  </Primitive>
</template>
