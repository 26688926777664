import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAmazon, LazySvgoArrowCurveDownMobile, LazySvgoArrowCurveDown, LazySvgoArrowDown, LazySvgoArrow, LazySvgoCheck, LazySvgoDepoimentos, LazySvgoHook, LazySvgoPlateIt, LazySvgoPlate, LazySvgoQrcode, LazySvgoQuotation, LazySvgoRocket, LazySvgoSaturn, LazySvgoScribble2, LazySvgoScribble3, LazySvgoScribble4, LazySvgoScribble, LazySvgoSpecialOffer, LazySvgoSquaresLeft, LazySvgoSquaresRight, LazySvgoStampOffer, LazySvgoStar, LazySvgoTrophy, LazySvgoWaitingList, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoAmazon", LazySvgoAmazon],
["SvgoArrowCurveDownMobile", LazySvgoArrowCurveDownMobile],
["SvgoArrowCurveDown", LazySvgoArrowCurveDown],
["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrow", LazySvgoArrow],
["SvgoCheck", LazySvgoCheck],
["SvgoDepoimentos", LazySvgoDepoimentos],
["SvgoHook", LazySvgoHook],
["SvgoPlateIt", LazySvgoPlateIt],
["SvgoPlate", LazySvgoPlate],
["SvgoQrcode", LazySvgoQrcode],
["SvgoQuotation", LazySvgoQuotation],
["SvgoRocket", LazySvgoRocket],
["SvgoSaturn", LazySvgoSaturn],
["SvgoScribble2", LazySvgoScribble2],
["SvgoScribble3", LazySvgoScribble3],
["SvgoScribble4", LazySvgoScribble4],
["SvgoScribble", LazySvgoScribble],
["SvgoSpecialOffer", LazySvgoSpecialOffer],
["SvgoSquaresLeft", LazySvgoSquaresLeft],
["SvgoSquaresRight", LazySvgoSquaresRight],
["SvgoStampOffer", LazySvgoStampOffer],
["SvgoStar", LazySvgoStar],
["SvgoTrophy", LazySvgoTrophy],
["SvgoWaitingList", LazySvgoWaitingList],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
