<script setup lang="ts">
import { Vue3Lottie } from "vue3-lottie";

const { locale } = useI18n();

const lottieTitles = {
  it: {
    desktop: "/lotties/hero-banner-title-it.json",
    mobile: "/lotties/hero-banner-title-it-mobile.json",
  },
  "pt-BR": {
    desktop: "/lotties/hero-banner-title.json",
    mobile: "/lotties/hero-banner-title-mobile.json",
  },
};

const banners = {
  it: {
    desktop: "/images/elements/a-arte-da-influencia-livro-comprar-preco-it.png",
    mobile:
      "/images/elements/a-arte-da-influencia-livro-comprar-preco-it-mobile.png",
  },
  "pt-BR": {
    desktop: "/images/elements/a-arte-da-influencia-livro-comprar-preco.png",
    mobile:
      "/images/elements/a-arte-da-influencia-livro-comprar-preco-mobile.png",
  },
};

const lottieTitle = computed(() => {
  return (
    lottieTitles[locale.value as keyof typeof lottieTitles] ??
    lottieTitles["pt-BR"]
  );
});

const banner = computed(() => {
  return banners[locale.value as keyof typeof banners] ?? banners["pt-BR"];
});
</script>

<template>
  <UtilsColoredSeparator />

  <div
    class="bg-[url(/images/backgrounds/light-blue-bg.jpg)] bg-cover bg-[center_top] relative flex flex-col items-center justify-center md:pt-[15vw] pt-[70vw] pb-14 md:pb-0"
  >
    <div
      class="absolute top-5 z-20 items-end flex justify-end w-full container max-w-screen-lg"
    >
      <UtilsLanguageSelector />
    </div>
    <ClientOnly>
      <div class="absolute top-0 w-[50%] hidden md:block">
        <Vue3Lottie
          :animationLink="lottieTitle.desktop"
          height="auto"
          width="100%"
        />
      </div>
      <div class="absolute md:top-0 top-10 w-[100%] block md:hidden">
        <Vue3Lottie
          :animationLink="lottieTitle.mobile"
          height="auto"
          width="100%"
        />
      </div>
      <div class="absolute top-10 w-[80%] hidden md:block">
        <Vue3Lottie
          animationLink="/lotties/hero-banner-elements.json"
          height="auto"
          width="100%"
        />
      </div>
    </ClientOnly>
    <picture>
      <source media="(min-width: 768px)" :srcset="banner.desktop" />
      <img
        :src="banner.mobile"
        class="md:h-[32vw] w-full md:w-auto relative z-10"
      />
    </picture>

    <UtilsButton
      bordered
      as="a"
      href="#buy-now"
      scroll-to="buy-now"
      class="border-white text-white hover:text-white hover:border-white md:absolute md:bottom-20 z-20"
    >
      {{ $t("buy-now.button") }}
    </UtilsButton>
  </div>
</template>
