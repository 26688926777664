<script setup lang="ts">
const isVisible = ref(false);
const isScrolling = ref(false);

onMounted(() => {
  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  });
});

function scrollup() {
  isScrolling.value = true;

  window.scrollTo({ top: 0, behavior: "smooth" });
  setTimeout(() => {
    isScrolling.value = false;
  }, 1000);
}
</script>
<template>
  <div
    @click="scrollup"
    :class="[
      'bg-[#3680d4]',
      'scrollup',
      isVisible && 'visible',
      isScrolling && 'scrolling',
    ]"
  ></div>
</template>
<style scoped lang="scss">
.scrollup {
  position: fixed;
  bottom: 20px;
  right: 15px;

  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 10px #00000040;
  border: 2px solid #fff;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 1;

  transition: all 0.7s;
  opacity: 0;
  &.visible {
    opacity: 1;
  }

  &.scrolling {
    transform: translateY(-400px);
    opacity: 0;
  }

  &:hover {
    &:before {
      animation: upMe 1s infinite;
    }
  }
  &:before {
    content: "↑";
    font-size: 20px;
  }
}

@keyframes upMe {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-3px);
  }
}
</style>
