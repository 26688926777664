import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


export default defineNuxtPlugin(() => {
    const firebaseConfig = {
        apiKey: "AIzaSyAS2OkoM_U37r45gtpp71ytMfIrHRNXjfA",
        authDomain: "a-arte-da-influencia.firebaseapp.com",
        projectId: "a-arte-da-influencia",
        storageBucket: "a-arte-da-influencia.firebasestorage.app",
        messagingSenderId: "1067452918609",
        appId: "1:1067452918609:web:51283cfd50e3461f5c0170",
        measurementId: "G-LJWZRRM81B"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const firestore = getFirestore(app, 'aartedainfluencia');

    return {
        provide: {
            firebase: app,
            analytics: analytics,
            firestore: firestore
        }
    }
})
