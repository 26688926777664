<script setup lang="ts">
import { cn } from "~/lib/utils";

const props = defineProps<{
  class?: string;
  containerClass?: string;
  wrapperClass?: string;
  rounded?: "none" | "sm" | "md" | "lg" | "xl" | "full";
  
}>();

const roundedClass = [
  props.rounded === "none" && "rounded-none",
  props.rounded === "sm" && "rounded-sm",
  props.rounded === "md" && "rounded-md",
  props.rounded === "lg" && "rounded-2xl",
  props.rounded === "xl" && "rounded-3xl",
];
</script>
<template>
  <div
    :class="
      cn(
        'bg-[linear-gradient(to_top_right,#27F4E8_0%,#27F4E820_50%,#EE382477_100%)] shadow-lg',
        ...roundedClass,
        props.containerClass
      )
    "
  >
    <div
      :class="
        cn(
          'overflow-hidden relative px-5 py-7 border-2 border-transparent h-full flex items-center',
          ...roundedClass,
          wrapperClass
        )
      "
    >
      <div class="absolute bg-white inset-0"></div>
      <div
        class="absolute -top-52 -left-52 h-96 w-96 bg-[radial-gradient(circle,rgba(24,155,230,0.10)_0%,rgba(255,255,255,0.00)_50%)]"
      ></div>
      <div
        class="absolute -bottom-52 -right-52 h-96 w-96 bg-[radial-gradient(circle,rgba(238,56,36,0.10)_0%,rgba(255,255,255,0.00)_50%)]"
      ></div>
      <div :class="cn('z-10 relative w-full', props.class)">
        <slot />
      </div>
    </div>
  </div>
</template>
