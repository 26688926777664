<script setup lang="ts">
import Separator from "../utils/Separator.vue";

const { locale } = useI18n();

const videoUrl = computed(() => {
  return locale.value === "it"
    ? "https://www.youtube.com/embed/sAvZCw2Gwv4?si=kEKZDJCY5iSOFU0_"
    : "https://www.youtube.com/embed/RO4d_2orAI8?si=oIRXSd0QGL8yWufz";
});
</script>

<template>
  <UtilsSection
    container-class="flex flex-col items-center"
    id="video-presentation"
  >
    <div class="w-full relative mt-16 mb-24" data-aos="fade-up">
      <div class="flex items-center justify-center relative">
        <SvgoScribble2
          class="w-[120px] absolute -right-0 -top-10 z-10"
          :font-controlled="false"
          :filled="true"
        />
        <SvgoScribble3
          class="w-[120px] absolute -left-0 -bottom-10 z-10"
          :font-controlled="false"
          :filled="true"
        />
        <UtilsCard
          class="p-1 flex flex-col items-center md:min-h-96 min-h-[40vw]"
          rounded="xl"
          container-class="md:w-[90%] w-[100%]"
          wrapper-class="px-0"
        >
          <UtilsTitle as="h2" class="text-center text-lg md:text-5xl">
            {{ $t("video.title") }}
          </UtilsTitle>
        </UtilsCard>
      </div>
      <div class="absolute md:top-[90px] top-[50px] w-full">
        <div class="flex items-center justify-center">
          <iframe
            class="md:w-[80%] w-[95%] aspect-[1.9] rounded-3xl mt-5 relative z-20"
            :src="videoUrl"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </UtilsSection>
  <Separator class="mt-5 mb-5" position="left" />
</template>
